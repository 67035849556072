<template>
  <div class="row">
    <div class="col-12 col-md-10 col-lg-11 col-xl-8 m-auto">
      <div class="card">
        <div class="card-header pb-0">
          <h1>Advance Filter</h1>
        </div>
        <div class="card-content pb-1">
          <div class="card-body">
            <div class="row pb-2">
              <div class="col-lg-6 col-xs-12 col-sm-12">
                <div class="mb-xs-3">

                  <UserContactFilter
                    @onSearch="startFiltering"
                  ></UserContactFilter>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 col-md-10 col-lg-11 col-xl-8 m-auto">
      <div class="card" v-for="item in contacts">
        <div class="card-content collapse show" aria-expanded="false">
          <div class="card-body">
            <div class="row">
              <div class="col-md-3">
                <img style="width: 80%" src="/app-assets/images/avatar.png" alt="avatar" class="img-thumbnail">
              </div>
              <div class="col-md-8">
                <router-link :to="{name: 'contact.profile.details', params:{contactId: item.contact_id??0}}"
                             class="text-dark d-block w-100 h-100">
                  <p>Name: {{ item.contact_profile.full_name ?? 'N/A' }}</p>
                  <p>Phone: {{ this.formatSingleItem(item, 'phone') }}</p>
                  <p>Email: {{ this.formatSingleItem(item, 'email') }}</p>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserContactFilter from "@/components/molecule/contact/UserContactFilter";
import handleContact from "@/services/modules/contact";
import {inject} from "vue";
import {has} from "lodash";
export default {
  name: "ContactFilter",
  components:{
    UserContactFilter: UserContactFilter,
  },
  data: () => ({
    contacts: [],
  }),
  methods:{
    formatSingleItem(item, field) {
      if(has(item, `contact_profile.contact_profile_items.${field}`)) return item.contact_profile.contact_profile_items[field][0].value
      return 'N/A'
    },
    async startFiltering(form){
      try{
        let query = this.createQuery(form)
        let res = await this.searchUserOwnContacts(query)
        if(res.status) {
          this.contacts = res.data.data
          this.showSuccess(res.message)
        }
        if(!res.status) {
          this.showError("No contacts found")
          this.contacts = []
        }
      }catch (e) {
        console.error(e)
        this.showError('Something went wrong')
      }

    },
  },
  setup(){
    const showError = inject('showError');
    const showSuccess = inject('showSuccess');

    const {
      createQuery,
      filterUserOwnContacts,
      loading,
    } = handleContact()

    return {
      createQuery,
      searchUserOwnContacts:filterUserOwnContacts,
      loading,
      showSuccess,
      showError
    }
  },
}
</script>

<style scoped>

</style>