<template>
  <div class="row">
    <template v-for="(config, field, index) in fields" :key="config.name">
      <div class="col-md-12" v-if="fields.hasOwnProperty(field)">
        <label :for="config.name" class="form-label">{{ config.label }}</label>
        <input
            type="text"
            class="form-control form-control rounded-0 mb-1"
            :placeholder="config.placeHolder"
            :id="config.name"
            :name="config.name"
            v-model="form[field]"
        />
      </div>
    </template>
    <div class="col-md-12" v-if="Object.keys(filterableFields).length > 0">
      <label class="form-label" for="basicSelect">Select Fields</label>
      <div class="input-group mb-3">
        <select class="form-select rounded-0" id="basicSelect" v-model="selectedFilterableField">
          <template v-for="(label, field, index) in filterableFields" :key="field">
            <option :value="field">{{ label }}</option>
          </template>
        </select>
        <span
            class="ms-1 btn btn-success btn-icon btn-small waves-float waves-light rounded-0"
            @click="addSearchField"
        >
          <i class="fas fa-plus"></i>
        </span>
      </div>
    </div>

    <div class="col-12">
      <button class="btn btn-success btn-icon btn-small waves-float waves-light rounded-0" @click="$emit('onSearch', form)"> Search </button>
    </div>
  </div>
</template>

<script>
import {omit} from "lodash";

export default {
  name: "UserContactFilter",
  emits: ['onSearch'],
  data: () => ({
    selectedFilterableField:'email',
    form: {},
    fields: {
      full_name: {
        label: 'Name',
        placeHolder: 'Enter name to search',
        name: 'name'
      },
      phone: {
        label: 'Phone',
        placeHolder: 'Enter phone to search',
        name: 'phone'
      }
    },

    filterableFields: {
      email: 'Email',
      location: 'Location',
    },


  }),
  methods:{
    addSearchField(){
      this.fields[this.selectedFilterableField] = {
        label: this.filterableFields[this.selectedFilterableField],
        placeHolder: `Enter ${this.filterableFields[this.selectedFilterableField]} to search`,
        name: this.selectedFilterableField
      }
      this.filterableFields = omit(this.filterableFields, [this.selectedFilterableField])
      this.selectedFilterableField = Object.keys(this.filterableFields)[0]
    },
  }
}
</script>

<style scoped>

</style>